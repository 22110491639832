import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BasicVideo = makeShortcode("BasicVideo");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "support--acceptance--confidence"
    }}>{`Support = Acceptance + Confidence`}</h1>
    <p>{`The good news is, there are more helpful ways to respond to your teenager’s
anxiety! Watch how Jenny responds to Liliana’s anxiety in this video:`}</p>
    <BasicVideo url="https://player.vimeo.com/video/575138036?h=646bb620c3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" mdxType="BasicVideo" />
    <p>{`Notice how Jenny acknowledges Liliana’s anxiety (`}<strong parentName="p">{`acceptance`}</strong>{`), but did
not ‘take over’. Instead, she remains empathic and calm, and supports Liliana
to face her anxiety and problem solve the situation, while expressing `}<strong parentName="p">{`confidence`}</strong>{` in Liliana’s ability to do so.`}</p>
    <p>{`This combination of acceptance + confidence is the kind of supportive response
that can empower teenagers.`}<sup>{`2`}</sup>{` Over time, it builds their confidence
and skills in facing their fears and overcoming their anxiety.`}</p>
    <h3 {...{
      "id": "remember"
    }}>{`Remember...`}</h3>
    <p><strong parentName="p">{`Making changes can be hard.`}</strong>{` Keep at it! The long-term benefits are
worth it. Seek professional help if you need more support.
It is important to increase your supportive behaviours, `}<strong parentName="p">{`before and while`}</strong>{` actively reducing your accommodating behaviours. Let’s
look at some ways you can support your teenager with their anxiety.`}</p>
    <sup>
  2. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      